/* globals window */

import React from 'react';
import { Provider } from 'react-redux';
import scss from './src/assets/scss/init.scss'
import createStore from './src/state/createStore';

const store = createStore();

export default ({ element }) => (
  <Provider store={store}>
      {element}
  </Provider>
);